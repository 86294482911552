import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Numpad from 'components/Numpad'
import TyreDimension from 'components/TyreDimension'
import If from 'components/If'
import Button from 'react-bootstrap/Button'
import LoadingOverlay from 'components/LoadingOverlay'
import ArticleTypeButtons from 'components/ArticleTypeButtons'
import TruckDimension from 'features/TruckDimension'
import LoaderDimension from 'features/LoaderDimension'
import RimDimension from 'components/RimDimension'
import TruckRimDimension from 'features/TruckRimDimension'
import BarCodeScanner from 'features/BarCodeScanner'
import articleType from 'enums/articleType'
import SvgKeyboard from 'assets/svgs/svgr/svgr/svgr/Keyboard'
import SvgBarcodes from 'assets/svgs/svgr/svgr/svgr/Barcodes'
import { useNavigate } from 'react-router-dom/dist'
import { usePrevious } from 'lib/utils'
import { toggleShowScanner } from 'features/BarCodeScanner/barCodeScannerSlice'
import { useLazyGetBrandsQuery } from 'api/brandsApi'

import './style.css'

const ArticleSearch = () => {
	const dispatch = useDispatch()

	const [criteria, setCriteria] = useState({
		showNumpad: true,
		showBrands: false,
		brandId: 0,
		modelId: 0,
		width: '',
		aspectRatio: '',
		diameter: '',
		bolts: '',
		circle: '',
		articleId: '',
		searchArticleId: false,
		inchSearch: false,
		selectedArticleType: 1,
		truckDimension: '',
		loaderDimension: '',
		truckRimDimension: '',
		disableNumpad: false,
	})


	const inputField = useRef(null)
	const navigate = useNavigate()

	const prevDisableNumpad = usePrevious(criteria.disableNumpad)

	const showScanner = useSelector((state) => state.barCodeScanner.showScanner)

	const [triggerGetBrands, { data: brands, isLoading: loading }] = useLazyGetBrandsQuery()

	const onClickNumber = (value, keyCode) => {
		if (criteria.searchArticleId) {
			setArticleNumber(value, keyCode)
		}
	}

	const onPresetDimensionChange = e => {
		const { name, value } = e.target
		onItemChange(name, value)
		const width = value.split(';')[0]?.replace(',', '.')
		const diameter = value.split(';')[2]?.replace(',', '.')
		let aspectRatio = value.split(';')[1]?.replace(',', '.')
		if (aspectRatio === '0') aspectRatio = ''
		onDimensionChange({ width, aspectRatio, diameter, inchSearch: false })
	}

	const onTruckRimDimensionChange = e => {
		const { name, value } = e.target
		onItemChange(name, value)
		const width = value.split(';')[0].replace(',', '.')
		const diameter = value.split(';')[1].replace(',', '.')
		onDimensionChange({ width, aspectRatio: null, diameter, incheSearch: false })
	}

	const setArticleNumber = (value, keyCode) => {
		if (!value && keyCode !== 8) return
		if (keyCode === 8) {
			onItemChange('articleId', criteria.articleId.slice(0, -1))
		} else {
			const newValue = criteria.articleId + value
			onItemChange('articleId', newValue)
		}
	}

	const onSubmit = (e) => {
		if (e)
			e.preventDefault()

		const payload = {
			articleSearchText: criteria.articleId,
			width: criteria.searchArticleId ? null : criteria.width,
			aspectRatio: criteria.searchArticleId ? null : criteria.aspectRatio,
			diameter: criteria.searchArticleId ? null : criteria.diameter,
			bolts: criteria.searchArticleId ? null : criteria.bolts,
			circle: criteria.searchArticleId ? null : criteria.circle,
			brandId: criteria.searchArticleId ? null : criteria.brandId,
			patternModelId: criteria.searchArticleId ? null : criteria.modelId,
			articleType: criteria.searchArticleId ? null : criteria.selectedArticleType,
		}

		if (criteria.searchArticleId) {
			navigate('/articlelist/article/', { state: { payload } })
		} else if (criteria.modelId && criteria.brandId) {
			navigate('/articlelist/article/', { state: { payload } })
		} else {
			triggerGetBrands({
				width: criteria.width,
				aspectRatio: criteria.aspectRatio,
				diameter: criteria.diameter,
				bolts: criteria.bolts,
				circle: criteria.circle,
				selectedArticleType: criteria.selectedArticleType
			})
			setCriteria({
				...criteria,
				showNumpad: false,
				showBrands: true,
			})
		}
	}

	const onDisableNumpad = (value) => {
		setCriteria({
			...criteria,
			disableNumpad: value,
		})
	}
	const onDimensionChange = (payload) => {
		const { width, aspectRatio, diameter, bolts, circle, inchSearch } = payload
		setCriteria(prevCriteria => ({
			...prevCriteria,
			width,
			aspectRatio,
			diameter,
			bolts,
			circle,
			inchSearch,
		}))
	}

	const clearSearch = () => {
		setCriteria({
			...criteria,
			brands: null,
			showNumpad: true,
			showBrands: false,
			brandId: 0,
			modelId: 0,
			articles: null,
			width: '',
			aspectRatio: '',
			diameter: '',
			bolts: '',
			circle: '',
			articleId: '',
			inchSearch: false,
			truckDimension: '',
			loaderDimension: '',
			truckRimDimension: '',
		})
	}

	const onKeyboardBtnClick = () => {
		onDisableNumpad(!criteria.disableNumpad)
		clearSearch()
	}

	const goBack = () => {
		clearSearch()
		navigate(-1)
	}

	const onItemChange = (name, value) => {
		setCriteria({
			...criteria,
			[name]: value,
		})
	}

	const onChange = e => {
		const { name, value } = e.target
		onItemChange(name, Number(value))
	}

	const onChangeId = e => {
		const { name, value } = e.target
		onItemChange(name, value)
	}


	const onChangeArticleType = (type) => {
		const disableNumpad = type !== articleType.tyre && type !== articleType.rim && type !== articleType.MOTO
		setCriteria({
			...criteria,
			selectedArticleType: type,
			disableNumpad: disableNumpad,
			searchArticleId: false,
			width: '',
			aspectRatio: '',
			diameter: '',
			bolts: '',
			circle: '',
		})
	}

	const toggleType = () => {
		setCriteria({
			...criteria,
			searchArticleId: !criteria.searchArticleId,
			selectedArticleType: 1,
			disableNumpad: false,
		})
	}

	const toggleScanner = () => {
		dispatch(toggleShowScanner())
	}

	const onNewScanResult = (decodedText, decodedResult) => {
		onItemChange('articleId', decodedText)
	}

	useEffect(() => {
		if (!prevDisableNumpad && criteria.disableNumpad && document.activeElement.id === 'switchInputType') {
			inputField.current.focus()
		}
	}, [loading, prevDisableNumpad, criteria.disableNumpad, navigate])

	return (
		<Form onSubmit={onSubmit} className="articleSearch">
			<ArticleTypeButtons onClick={onChangeArticleType} active={criteria.selectedArticleType} />
			<div className="dimensionWrapper">
				<If condition={criteria.searchArticleId}>
					<div className="input-group mb-3">
						<input
							className="form-control"
							type="text"
							ref={inputField}
							disabled={!criteria.disableNumpad}
							name="articleId"
							value={criteria.articleId}
							onChange={onChangeId}
							placeholder="Article number / EAN / SupplierId"
							aria-describedby="basic-addon2"
						/>
						<div className="input-group-append">
							<button className="btn btn-outline-secondary" type="button" id={'switchInputType'} onClick={onKeyboardBtnClick}>
								<SvgKeyboard />
							</button>
							<button className="btn btn-outline-secondary" type="button" id={'switchInputType'} onClick={toggleScanner}>
								<SvgBarcodes />
							</button>
						</div>
					</div>
				</If>

				<If condition={!criteria.searchArticleId && criteria.selectedArticleType === articleType.tyre}>
					<TyreDimension
						onChange={onDimensionChange}
						width={criteria.width}
						aspectRatio={criteria.aspectRatio}
						diameter={criteria.diameter}
						inchSearch={criteria.inchSearch}
						showNumpad={criteria.showNumpad}
						onSubmit={onSubmit}
					/>
				</If>
				<If condition={!criteria.searchArticleId && criteria.selectedArticleType === articleType.truckTyre}>
					<TruckDimension
						disabled={!criteria.showNumpad}
						onChange={onPresetDimensionChange}
						selectedItem={criteria.truckDimension} />
				</If>
				<If condition={!criteria.searchArticleId && criteria.selectedArticleType === articleType.loadTyre}>
					<LoaderDimension
						disabled={!criteria.showNumpad}
						onChange={onPresetDimensionChange}
						selectedItem={criteria.loaderDimension} />
				</If>
				<If condition={!criteria.searchArticleId && criteria.selectedArticleType === articleType.rim}>
					<RimDimension
						onChange={onDimensionChange}
						diameter={criteria.diameter}
						width={criteria.width}
						bolts={criteria.bolts}
						circle={criteria.circle}
						inchSearch={criteria.inchSearch}
						showNumpad={criteria.showNumpad}
						onSubmit={onSubmit}
					/>
				</If>
				<If condition={!criteria.searchArticleId && criteria.selectedArticleType === articleType.truckRims}>
					<TruckRimDimension
						disabled={!criteria.showNumpad}
						onChange={onTruckRimDimensionChange}
						selectedItem={criteria.truckRimDimension} />
				</If>
				<If condition={!criteria.searchArticleId && criteria.selectedArticleType === articleType.MOTO}>
					<TyreDimension
						onChange={onDimensionChange}
						width={criteria.width}
						aspectRatio={criteria.aspectRatio}
						diameter={criteria.diameter}
						showNumpad={criteria.showNumpad}
						onSubmit={onSubmit} />
				</If>
			</div>
			<If condition={!criteria.showNumpad}>
				<Form.Group>
					<Form.Control
						disabled={!brands}
						name="brandId"
						onChange={onChange}
						value={criteria.brandId} as="select"
						size="lg"
					>
						<option value="0">- Select brand -</option>
						{brands ? brands.map(item => (<option key={item.id} value={item.id}>{item.name}</option>)) : ''}
					</Form.Control>
				</Form.Group>
				<Form.Group>
					<Form.Control
						disabled={!criteria.brandId}
						name="modelId"
						onChange={onChange}
						value={criteria.modelId}
						as="select"
						size="lg"
					>
						<option value="0">- Select pattern -</option>
						{criteria.brandId ? brands.find(b => b.id === criteria.brandId).patternModels.map(item => (<option key={item.id} value={item.id}>{item.name}</option>)) : ''}
					</Form.Control>
				</Form.Group>
			</If>
			<If condition={criteria.disableNumpad || criteria.searchArticleId}>
				<Form.Group>
					<Numpad
						onClick={onClickNumber}
						onSend={onSubmit}
						showStockButton={false}
						allowCharacters={criteria.disableNumpad}
						buttonText="Search"
						hideExtraButton={criteria.searchArticleId}
						disabled={criteria.disableNumpad}
					/>
				</Form.Group>
			</If>
			<If condition={criteria.showBrands}>
				<Form.Group>
					<Button variant="success" type="submit" size="lg">Search</Button>
				</Form.Group>
			</If>
			<If condition={!criteria.showBrands}>
				<Form.Group>
					<Button variant="info" type="button" onClick={toggleType} size="lg">{criteria.searchArticleId ? 'Dimension' : 'Article number / EAN / Supplier Id'}</Button>
				</Form.Group>
			</If>
			<Form.Group>
				<ButtonGroup size="lg">
					<Button variant="danger" onClick={goBack}>Home</Button>
					<Button variant="outline-dark" onClick={clearSearch}>Clear</Button>
				</ButtonGroup>
			</Form.Group>
			{loading ? <LoadingOverlay /> : ''}
			{showScanner && (<BarCodeScanner onScannedResult={onNewScanResult} />)}
		</Form>
	)
}

export default ArticleSearch

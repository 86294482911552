import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import PrivateRoute from 'components/PrivateRoute'
import * as serviceWorker from 'serviceWorker'
import classNames from 'classnames'

import Article from 'features/Article'
import Start from 'features/Start'
import Login from 'features/Login'
import NotFound from 'features/NotFound'
import StockLocationSearch from 'features/StockLocationSearch'
import InboundDelivery from 'features/InboundDelivery'
import ArticleList from 'features/ArticleList'
import StockCorrection from 'features/StockCorrection'
import MoveArticle from 'features/MoveArticle'
import ArticleSearch from 'features/ArticleSearch'
import ArticleStockLocationList from 'features/ArticleStockLocationList'
import MessageBox from 'features/MessageBox'
import NewVersion from 'features/NewVersion/NewVersion'
import InboundOrder from 'features/InboundOrder'
import Picking from 'features/Picking'
import StockTaking from 'features/StockTaking'
import RollingInventory from 'features/RollingInventory/RollingInventory'
import usePrinters from 'lib/hooks/usePrinters'
import ConnectionError from 'components/ConnectionError/ConnectionError'
import { useLazyFetchSettingsQuery } from 'api/appApi'

import './App.css'
import { useLazyCheckLoginQuery, useLazyFetchUserQuery } from 'api/loginApi'

const App = (props) => {
	const { setupPrinters } = usePrinters()

	const [waitingWorker, setWaitingWorker] = useState(null)
	const [newVersion, setNewVersion] = useState(false)

	const connectionError = useSelector((state) => state.connection.connectionError)

	const [triggerFetchSettings] = useLazyFetchSettingsQuery()
	const [triggerCheckLogin] = useLazyCheckLoginQuery()
	const [triggerFetchUser] = useLazyFetchUserQuery()

	const reloadPage = () => {
		if (waitingWorker) waitingWorker.postMessage({ type: 'SKIP_WAITING' })

		window.location.reload(true)
	}

	const onServiceWorkerUpdate = (registration) => {
		if (registration?.waiting) {
			// eslint-disable-next-line
			console.log('New version available!')
			setWaitingWorker(registration.waiting)
			setNewVersion(true)
		}
	}

	useEffect(() => {
		triggerCheckLogin()
		triggerFetchUser()
		triggerFetchSettings()
		serviceWorker.register({ onUpdate: onServiceWorkerUpdate })
		setupPrinters()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Router>
			<div className={classNames('App', { staging: props.isStaging })}>
				{(connectionError && props.loggedIn) ? <ConnectionError /> : null}
				<Routes>
					<Route element={<Login />} path="/login" exact />
					<Route element={<PrivateRoute authenticated={props.loggedIn} initialCheck={props.initialCheck} />}>
						<Route element={<Start />} path='/' exact />
						<Route element={<StockLocationSearch />} path='/stockLocation' exact />
						<Route element={<ArticleList />} path='/articlelist/:searchType' exact />
						<Route element={<StockCorrection />} path='/stockcorrection/:articleId/:stockLocation/:dotYear?' exact />
						<Route element={<MoveArticle />} path='/movearticle/:articleId/:stockLocation/:dotYear?' exact />
						<Route element={<ArticleSearch />} path='/articlesearch' exact />
						<Route element={<ArticleStockLocationList />} path='/stocklocations/:articleId/:stockLocation?' exact />
						<Route element={<Article />} path='/article/:articleId/:stockLocation/:dotYear?' exact />
						<Route element={<InboundOrder />} path='/inboundDelivery/:sequenceNumber' exact />
						<Route element={<InboundDelivery />} path='/inboundDelivery' exact />
						<Route element={<Picking />} path='/picking' exact />
						<Route element={<StockTaking />} path='/stocktaking/:sequenceNumber' exact />
						<Route element={<RollingInventory />} path='/rollingInventory' exact />
						<Route element={<NotFound />} path='*' />
					</Route>
				</Routes>

				{props.showMessage && <MessageBox />}
				{newVersion && <NewVersion onReload={reloadPage} />}
			</div>
		</Router>
	)
}

const mapStateToProps = (state) => ({
	loggedIn: state.login.loggedIn,
	initialCheck: state.login.initialCheck,
	showMessage: state.messageBox.showMessage,
	isStaging: state.app.settings.isStaging,
})

export default connect(mapStateToProps)(Sentry.withProfiler(App))
